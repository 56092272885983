import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {
    overlay: true,
    createSpinner: false,
    success: false,
  },
  getters: {},
  mutations: {
    setOverlay(state, value) {
      state.overlay = value
    },
    setCreateSpinner(state, value) {
      state.createSpinner = value
    },
    setSuccess(state, value) {
      state.success = value
    },
  },
  actions: {
    /**
     * fetch invoice
     * @param ctx
     * @param token
     * @returns {Promise<unknown>}
     */
    fetchInvoice(ctx, token) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}public/invoices/${token}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /**
     * create arrangement
     * @param ctx
     * @param data
     * @returns {Promise<unknown>}
     */
    createArrangement(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}public/invoices/${data.token}/setarrangement`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
