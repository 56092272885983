<template>
  <div>
    <b-row
      v-if="invoiceData && invoiceData.arrangementAllowed"
      class="match-height"
    >
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          v-if="!success"
          headless
        >
          Beste {{ getFullName(invoiceData.invoice.orgRelation) }},<br>
          <br>
          Wij bieden je de mogelijkheid om een betalingsregeling te treffen mocht het betalen van het openstaande bedrag voor jouw een probleem zijn.
          Houdt je aan de gemaakte afspraken, om te voorkomen dat wij de betalingsregeling beëindigen en eventueel een incassobureau ingeschakelen.
          <br><br>
          <b-row>
            <b-col
              class="mb-1"
              cols="12"
              md="4"
            >
              <div class="media">
                <div class="media-aside mr-1 align-self-start">
                  <div
                    class="b-avatar badge-light-primary rounded"
                    style="width: 34px; height: 34px;"
                  >
                    <div
                      class="b-avatar-custom"
                    >
                      <feather-icon
                        icon="HashIcon"
                        size="19"
                      />
                    </div>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="mb-0">
                    Factuurnummer
                  </h6>
                  <small>{{ invoiceData.invoice.invoiceNumber }}</small>
                </div>
              </div>
            </b-col>
            <b-col
              class="mb-1"
              cols="12"
              md="4"
            >
              <div class="media">
                <div class="media-aside mr-1 align-self-start">
                  <div
                    class="b-avatar badge-light-primary rounded"
                    style="width: 34px; height: 34px;"
                  >
                    <div
                      class="b-avatar-custom"
                    >
                      <feather-icon
                        icon="ClockIcon"
                        size="19"
                      />
                    </div>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="mb-0">
                    Factuurdatum
                  </h6>
                  <small>{{ formatDate(invoiceData.invoice.invoiceDate) }}</small>
                </div>
              </div>
            </b-col>
            <b-col
              class="mb-1"
              cols="12"
              md="4"
            >
              <div class="media">
                <div class="media-aside mr-1 align-self-start">
                  <div
                    class="b-avatar badge-light-primary rounded"
                    style="width: 34px; height: 34px;"
                  >
                    <div
                      class="b-avatar-custom"
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="19"
                      />
                    </div>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="mb-0">
                    Bedrag
                  </h6>
                  <small>{{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(invoiceData.invoice.openAmount) }}</small>
                </div>
              </div>
            </b-col>
          </b-row>
          Met vriendlijke groet,
          <br><br>

          HEM
        </b-card>
        <b-card
          v-else-if="success"
          headless
        >
          De betalingsregeling is met succes aangemaakt. Als je vragen hebt neem dan contact op met de klantenservice van HEM. Wij zijn te bereiken op werkdagen op 020 225 99 34 van 09:00 tot 17:00 uur.
        </b-card>
      </b-col>
      <b-col
        v-if="!success"
        cols="12"
        md="6"
      >
        <b-card
          headless
        >
          <validation-observer
            ref="refFormPaymentArrangement"
            #default="{ handleSubmit }"
          >
            <b-form
              @submit.prevent="handleSubmit(createPaymentArrangement)"
            >
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="advancedPaymentInstallmentCount"
                  rules="required"
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                    label="Aantal termijnen"
                    label-for="advancedPaymentInstallmentCount"
                  >
                    <v-select
                      v-model="arrangementModel.installmentCount"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="maxTermCount"
                      :reduce="val => val.value"
                      label="key"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="advancedPaymentFirstInstallmentDate"
                  rules="required"
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                    label="Eerste betaaldatum"
                    label-for="advancedPaymentFirstInstallmentDate"
                  >
                    <flat-pickr
                      id="advancedPaymentFirstInstallmentDate"
                      v-model="arrangementModel.firstInstallmentDate"
                      :config="{
                        altFormat: 'd-m-Y',
                        dateFormat: 'd-m-Y',
                        altInput: true,
                        allowInput: true,
                        minDate: 'today',
                        maxDate: todayPlusDays(14),
                      }"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="advancedPaymentInstallmentNote"
                  rules="required"
                >
                  <b-form-group
                    label="Reden"
                    label-for="advancedPaymentInstallmentNote"
                  >
                    <b-form-textarea
                      id="advancedPaymentInstallmentNote"
                      v-model="arrangementModel.note"
                      :state="getValidationState(validationContext)"
                      placeholder=""
                      rows="4"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12 text-right mt-1 mb-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="createSpinner"
                  type="submit"
                  variant="secondary"
                >
                  <b-spinner
                    v-if="createSpinner"
                    class="mr-1"
                    small
                  />
                  <span>Aanmaken</span>
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else-if="invoiceData && !invoiceData.arrangementAllowed">
      <b-col cols="12">
        <b-card headless>
          <b-card-text>
            De link waarmee je deze pagina probeert te bezoeken is niet meer geldig en/of verlopen. Neem contact op met de klantenservice van HEM voor meer informatie. Op werkdagen te bereiken op 020 225 99 34 van 09:00 tot 17:00 uur.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInvalidFeedback, BFormGroup, BForm, BButton, BFormTextarea, BSpinner, BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import {
  onMounted, onUnmounted, ref, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import arrangementStoreModule from '@/views/pages/arrangement/arrangementStoreModule'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import {
  formatDate, formatDateToBackendFormat, getFullName, todayPlusDays,
} from '@core/utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInvalidFeedback,
    BFormTextarea,
    BSpinner,
    BCardText,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const ARRANGEMENT_STORE_MODULE_NAME = 'app-arrangement'
    const overlay = computed(() => store.state[ARRANGEMENT_STORE_MODULE_NAME].overlay)
    const createSpinner = computed(() => store.state[ARRANGEMENT_STORE_MODULE_NAME].createSpinner)
    const success = computed(() => store.state[ARRANGEMENT_STORE_MODULE_NAME].success)
    const invoiceData = ref(null)
    const maxTermCount = ref([{ key: 1, value: 1 }])
    const arrangementModel = ref({
      installmentCount: null,
      firstInstallmentDate: '',
      note: '',
      id: null,
    })

    // Register module
    if (!store.hasModule(ARRANGEMENT_STORE_MODULE_NAME)) store.registerModule(ARRANGEMENT_STORE_MODULE_NAME, arrangementStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ARRANGEMENT_STORE_MODULE_NAME)) store.unregisterModule(ARRANGEMENT_STORE_MODULE_NAME)
    })

    /**
     *
     * @param amount
     *
     * Generate terms depending on invoice amount
     *
     */
    const generateMaxTerms = amount => {
      if (amount <= 200) {
        maxTermCount.value = [
          { key: 2, value: 2 },
        ]
      }
      if (amount > 200 && amount <= 450) {
        maxTermCount.value = [
          { key: 2, value: 2 },
          { key: 3, value: 3 },
        ]
      }
      if (amount > 450 && amount <= 800) {
        maxTermCount.value = [
          { key: 2, value: 2 },
          { key: 3, value: 3 },
          { key: 4, value: 4 },
        ]
      }
      if (amount > 800) {
        maxTermCount.value = [
          { key: 2, value: 2 },
          { key: 3, value: 3 },
          { key: 4, value: 4 },
          { key: 5, value: 5 },
        ]
      }
    }

    const createPaymentArrangement = () => {
      store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setCreateSpinner`, true)
      store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setSuccess`, false)
      const model = JSON.parse(JSON.stringify(arrangementModel.value))
      model.token = router.currentRoute.params.token
      model.firstInstallmentDate = formatDateToBackendFormat(model.firstInstallmentDate)
      store.dispatch(`${ARRANGEMENT_STORE_MODULE_NAME}/createArrangement`, model).then(() => {
        arrangementModel.value.note = ''
        arrangementModel.value.firstInstallmentDate = ''
        arrangementModel.value.installmentCount = null
        arrangementModel.value.id = null
        store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setCreateSpinner`, false)
        store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setSuccess`, true)
      }).catch(() => {
        arrangementModel.value.note = ''
        arrangementModel.value.installmentCount = null
        arrangementModel.value.firstInstallmentDate = ''
        arrangementModel.value.id = null
        store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setCreateSpinner`, false)
        Vue.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Betalingsregeling',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Er is iets fout gegaan bij het aanmaken van de betalingsregeling, probeer het nog een keer.',
          },
        })
      })
    }

    /**
     * @param token
     *
     * Get invoice data for token
     */
    const fetchInvoice = () => {
      // show page loader
      store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setOverlay`, true)
      // Get invoice ID from url param and get invoice details
      store.dispatch(`${ARRANGEMENT_STORE_MODULE_NAME}/fetchInvoice`, router.currentRoute.params.token)
        .then(response => {
          // invoice
          invoiceData.value = response?.data
          // Generate terms
          generateMaxTerms(invoiceData?.value?.invoice?.amount)
          // hide page loader
          store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setOverlay`, false)
        })
        .catch(() => {
          // hide page loader
          store.commit(`${ARRANGEMENT_STORE_MODULE_NAME}/setOverlay`, false)
          // show toast message
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van uw gegevens',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    onMounted(() => {
      // get invoice on mount
      fetchInvoice()
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      arrangementModel,
      createPaymentArrangement,
      maxTermCount,
      required,
      overlay,
      getFullName,
      invoiceData,
      todayPlusDays,
      formatDate,
      createSpinner,
      success,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.signature {
  border: solid 2px #000000;
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.signature.invalid {
  border: solid 2px #ea5455;
}

.invalid{
  .card-title{
    color: #ea5455
  }
}

.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
